import "./Portfolio.scss";
import { useEffect, useRef, useState } from "react";

//We don't care about the typing for figlet.
// @ts-ignore
import figlet from "figlet";
// @ts-ignore
import Slant from "figlet/importable-fonts/Slant.js";
// @ts-ignore
import Small from "figlet/importable-fonts/Small.js";

import { TypeAnimation } from "react-type-animation";
import useScreenSize from "./useScreenSize";

figlet.parseFont("Slant", Slant);
figlet.parseFont("Small", Small);

type TypingLineProps = {
  text: string;
  onFinished: () => any;
  delay?: number;
};

function TypingLine(p: TypingLineProps) {
  // How long will it take this to play?
  const timeToAnimate = p.text.length * 100 + 5;
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const s = setTimeout(() => {
      setFinished(true);
      p.onFinished();
    }, timeToAnimate + (p.delay || 10));

    return () => {
      clearTimeout(s);
    };
  });

  return (
    <div>
      <span className="green">{"\u279C"}</span>
      <span className="purple">&nbsp;~&nbsp;</span>
      <span className={finished ? "green" : ""}>
        <TypeAnimation
          sequence={[5, p.text]}
          cursor={false}
          speed={{ type: "keyStrokeDelayInMs", value: 100 }}
        />
      </span>
      <span className={"cursor" + (finished ? " hidden" : "")}>|</span>
    </div>
  );
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function RandomColorScheme() {
  let choices = [
    ["pink", "cyan"],
    ["purple", "cyan"],
    ["cyan", "pink"],
    ["cyan", "purple"],
    ["purple", "pink"],
    ["pink", "purple"],
  ];

  return choices[getRandomInt(6)];
}

const ColorScheme = ["cyan", "pink"];

const Resume = (props: { changePage: (p: Pages) => void }) => {
  const [isUnderlined, setIsUnderlined] = useState(false);
  const screenSize = useScreenSize();

  return (
    <div className="container">
      <div
        style={{ justifyContent: screenSize.width < 619 ? "center" : "start" }}
        className={`header ${ColorScheme[0]}`}
      >
        {figlet.textSync("Resume", {
          font: screenSize.width < 619 ? "Small" : "Slant",
          showHardBlanks: false,
        })}
      </div>
      <div>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            props.changePage(Pages.home);
          }}
          onMouseOver={() => {
            setIsUnderlined(true);
          }}
          onMouseLeave={() => {
            setIsUnderlined(false);
          }}
          className={isUnderlined ? "underline" : ""}
        >
          {"< Go Back"}
        </span>
      </div>
      <div className="tbox">
        <div className="legend-holder">
          <div className="legend">Education</div>
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Bachelors of Science in Computer Science</span>
          <span>May 2025</span>
        </div>
        <div>Texas Tech University</div>
        <div>Whitacre College of Engineering</div>
        <br></br>
      </div>
      <div className="tbox">
        <div className="legend-holder">
          <div className="legend">Professional Experience</div>
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Undergraduate Researcher | Department of Computer Science</span>
          <span>January 2024 - Current</span>
        </div>
        <div>Texas Tech University</div>
        <br />
        <div>
          - Working with MATLAB and Unity to create a software system and
          testbed application to simulate and conduct research on applications
          of drones for US Department of Homeland Security sponsored research
          project.
        </div>
        <br />
        <div>
          - Developing a Python API to communicate with the testbed application
          to allow other researchers to quickly attempt their experiments to the
          simulated drone.
        </div>
        <br />
        <div>
          - Applying knowledge of physics, advanced mathematics, and 3D software
          to accurately simulate the real-world kinematics of a quadcopter down
          to the rotor.
        </div>
        <br />
        <div>
          - Partnering with a team of fellow researchers and the principal
          investigator to set and track progress towards our goals for the
          research program
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Owner and Lead Developer</span>
          <span>July 2022 - Current</span>
        </div>
        <div>Texan Simulations</div>
        <br />
        <div>
          - Founded Texan Simulations, which focuses on creating and selling
          high-fidelity recreations of real-life airports for Microsoft Flight
          Simulator 2020.
        </div>
        <br />
        <div>
          - Successfully co-developed and launched our first commercial product,
          a rendition of William P. Hobby Airport (KHOU) in May 2023, which has
          now sold over 1500 units.
        </div>
        <br />
        <div>
          - Managed the end-to-end development process for the scenery to ensure
          on-time release.
        </div>
        <br />
        <div>
          - Developed custom software tools to automate complex and tedious
          tasks, which significantly reduced development time and increased
          efficiency.
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Service and Engagement Shift Lead</span>
          <span>December 2022 - January 2024</span>
        </div>
        <div>Target</div>
        <br />
        <div>
          - Demonstrated strong communication and leadership skills to ensure
          service and engagement team was always providing excellent customer
          service.
        </div>
        <br />
        <div>
          - Worked under pressure to handle providing service to customers while
          ensuring fellow employees went to their breaks on time.
        </div>
        <br />
        <div>
          - Responsible for ensuring all closing tasks and procedures were
          completed in a timely manner.
        </div>
        <br />
        <div>
          - Created tickets and tracked issues with physical registers to make
          sure they were returned to operation as soon as possible.
        </div>
        <br />
      </div>
      <div className="tbox">
        <div className="legend-holder">
          <div className="legend">Programming Languages and Frameworks</div>
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Programming Languages</div>
        <div>
          JavaScript, NodeJS, Java, C, C++, Swift, Python, JSX, HTML, CSS, C#,
          Prolog and MATLAB.
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Tools and Frameworks</div>
        <div>
          ExpressJS, Socket IO, React JS, React Native, SwiftUI, JavaFX,
          MongoDB, Firebase, SQLite, Redis, Unity and Maven.
        </div>
        <br />
      </div>
      <div className="tbox">
        <div className="legend-holder">
          <div className="legend">Technical Skills</div>
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Software Engineering</div>
        <div>
          Full Stack Application Development, iOS Development, and Software
          Systems Design.
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Algorithm Design</div>
        <div>
          Design and Analysis of Algorithms and Algorithm Optimization
          Techniques.
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Database Management</div>
        <div>Relational and Document Database Design, and Data Modeling.</div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Networking</div>
        <div>
          Information Technology Infrastructure, Networking Protocols, and
          Client-Server Architecture.
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Other</div>
        <div>
          Unix and Linux, GitHub, Git, Version Control, Dependency Management,
          and Cybersecurity Principles.
        </div>
        <br />
      </div>
    </div>
  );
};

const Content = (props: {
  changePage: (p: Pages) => void;
  shouldShowAnimation: boolean;
}) => {
  const [selected, setSelected] = useState(0);
  const [showContent, setShowContent] = useState(!props.shouldShowAnimation);
  const screenSize = useScreenSize();
  let selectedRef = useRef(0);

  const changeMenuItem = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowDown":
        var currentRef = selectedRef.current;
        setSelected((prevSelected) => (prevSelected + 1) % 6);
        selectedRef.current = (currentRef + 1) % 6;
        e.preventDefault();
        break;
      case "ArrowUp":
        var currentRef = selectedRef.current;
        setSelected((prevSelected) => (prevSelected - 1 + 6) % 6);
        selectedRef.current = (currentRef - 1 + 6) % 6;
        e.preventDefault();
        break;
      case "Enter":
        onSelect();
        e.preventDefault();
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", changeMenuItem);
    return () => {
      window.removeEventListener("keydown", changeMenuItem);
    };
  }, [changeMenuItem]);

  function isSelected(on: number) {
    if (selected === on) {
      return `selected ${ColorScheme[1]}`;
    } else {
      return "";
    }
  }

  function getHeaderText() {
    if (screenSize.width > 862) {
      return "Jackson Casey";
    } else if (screenSize.width <= 862 && screenSize.width > 618) {
      return "Jackson C";
    } else {
      return "J.C.";
    }
  }

  function onSelect() {
    switch (selectedRef.current) {
      case 0:
        props.changePage(Pages.resume);
        break;
      case 1:
        window.open("https://github.com/jaxcksn/CompSciDegreePlan");
        break;
      case 2:
        window.open("https://github.com/jaxcksn/FSMD");
        break;
      case 3:
        window.open("https://github.com/jaxcksn/CS2363-FinalProject");
        break;
      case 4:
        window.open("https://github.com/jaxcksn");
        break;
      case 5:
        window.open("https://www.linkedin.com/in/jackson-casey/");
        break;
      default:
        return;
    }
  }

  return (
    <div className="container">
      <div
        style={screenSize.width < 619 ? { justifyContent: "center" } : {}}
        className={`header ${ColorScheme[0]}`}
      >
        {figlet.textSync(getHeaderText(), {
          font: "Slant",
          showHardBlanks: false,
        })}
      </div>
      {props.shouldShowAnimation && (
        <TypingLine
          text="./portfolio"
          onFinished={() => {
            setShowContent(true);
          }}
        ></TypingLine>
      )}
      {!props.shouldShowAnimation && (
        <div>
          <span className="green">{"\u279C"}</span>
          <span className="purple">&nbsp;~&nbsp;</span>
          <span className={"green"}>{"./portfolio"}</span>
          <span className={"cursor hidden"}>|</span>
        </div>
      )}
      <br />
      {showContent && (
        <>
          <div className="highlight">Portfolio v0.2</div>
          <br />
          <div className="tbox">
            <div className="legend-holder">
              <div className="legend">About Me</div>
            </div>
            <div className="inner">
              <div className="firstBreak"></div>
              <span>
                Howdy, my name is Jackson, I'm a Junior studying Computer
                Science at Texas Tech University.
                <br></br>
                <br></br>
                Welcome to my Portfolio! You can check out some my projects, and
                view my resume on here.
              </span>
              <br></br>
            </div>
          </div>
          <br />
          <div className="tbox">
            <div className="legend-holder">
              <div className="legend">My Projects</div>
            </div>
            <div className="inner" style={{ flexDirection: "column" }}>
              <div className="hint">
                Use the arrow keys + enter, or click on a item.
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(0)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(0);
                    selectedRef.current = 0;
                  }}
                >
                  <i className="fa-solid fa-file mr"></i>
                  My Resume
                </span>
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(1)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(1);
                    selectedRef.current = 1;
                  }}
                >
                  <i className="fa-solid fa-building-columns mr"></i>
                  CS Degree Plan
                </span>
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(2)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(2);
                    selectedRef.current = 2;
                  }}
                >
                  <i className="fa-solid fa-terminal mr"></i>
                  FSMD CLI Tool
                </span>
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(3)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(3);
                    selectedRef.current = 3;
                  }}
                >
                  <i className="fa-solid fa-plane mr"></i>
                  Flight Reservation System
                </span>
              </div>
              <br />
            </div>
          </div>
          <br />
          <div className="tbox">
            <div className="legend-holder">
              <div className="legend">My Profiles</div>
            </div>
            <div className="inner" style={{ flexDirection: "column" }}>
              <div className="hint">
                Use the arrow keys + enter, or click on a item.
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(4)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(4);
                    selectedRef.current = 4;
                  }}
                >
                  <i className="fa-brands fa-github mr"></i>
                  Github
                </span>
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(5)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(5);
                    selectedRef.current = 5;
                  }}
                >
                  <i className="fa-brands fa-linkedin mr"></i>
                  LinkedIn
                </span>
              </div>
              <br />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

type ParamKeys = {
  [key: string]: string | null;
};

enum Pages {
  home,
  resume,
}

//MARK - Main Component
function Portfolio() {
  const [didShowResume, setDidShowResume] = useState(false);
  const [currentPage, setCurrentPage] = useState(Pages.home);

  const getComponentForPage = (page: Pages) => {
    if (page == Pages.resume) {
      //TODO - Replace with the resume component.
      return (
        <Resume
          changePage={(page: Pages) => {
            setDidShowResume(true);
            setCurrentPage(page);
          }}
        />
      );
    } else {
      return (
        <Content
          changePage={(page: Pages) => {
            setDidShowResume(true);
            setCurrentPage(page);
          }}
          shouldShowAnimation={!didShowResume}
        />
      );
    }
  };

  return getComponentForPage(currentPage);
}

export default Portfolio;
